import React from 'react';
import {Button, Checkbox, Divider, Form, Input, message, Row, Typography} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {auth} from "../../firebase";
import {signInWithEmailAndPassword} from 'firebase/auth';

const {Title} = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};

const Login = () =>  {
  const navigate = useNavigate();

  // email, password 로그인
  const onFinish = async (values: any) => {
    console.log('Received values of form: ', values);
    try {
      const {email, password} = values;
      const result = await signInWithEmailAndPassword(auth, email, password);

      // firestore에서 uid로 사용자 정보를 얻어서 redux에 설정
      console.log(result);

      navigate('/admin/log');
    } catch (error: any) {
      message.error(error.message);
    }
  };

  return (
    <div style={{padding: '1rem'}}>
      <Row justify="center">
        <Title level={3}>로그인</Title>
      </Row>

      <Divider>이메일 로그인</Divider>
      <Form
        {...formItemLayout}
        name="normal_login"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: '유효한 E-mail이 아닙니다!',
            },
            {
              required: true,
              message: 'E-mail을 입력해주세요!',
            },
          ]}
          style={{marginBottom: '0.5rem'}}
        >
          <Input prefix={<UserOutlined />} placeholder="이메일" />
        </Form.Item>
        <Form.Item
          name="password"
          label="패스워드"
          rules={[
            {
              required: true,
              message: '패스워드를 입력해주세요!',
            },
          ]}
          style={{marginBottom: '0.5rem'}}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="패스워드"
          />
        </Form.Item>
        <Form.Item {...tailFormItemLayout} style={{marginBottom: '0.5rem'}}>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" ghost htmlType="submit" block>
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
