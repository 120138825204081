import React, {useState} from 'react';
import {Button, Card, Col, DatePicker, Form, Row, Typography} from "antd";
import moment from "moment";
import {collection, DocumentData, getDocs, limit, orderBy, query, where} from "firebase/firestore";
import {firestore} from "../../../firebase";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

const AdminStats = () => {
  const [dataList, setDataList] = useState([]);
  const [skt, setSkt] = useState(0);
  const [kt, setKt] = useState(0);
  const [lg, setLg] = useState(0);
  const [total, setTotal] = useState(0);

  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const [startMoment, endMoment] = values.periodRange;
    const startDate = startMoment.format('YYYYMMDD');
    const endDate = endMoment.format('YYYYMMDD');

    const q = query(collection(firestore, 'stats'), orderBy('date', 'asc'), where('date', '>=', startDate), where('date', '<=', endDate));
    const querySnapshot = await getDocs(q);

    const tempData: any = [];
    querySnapshot.forEach((doc) => {
      tempData.push(doc.data());
    })

    setDataList(tempData);

    let skt = 0, kt = 0, lg = 0, total = 0;
    tempData.forEach((item: any) => {
      skt += item.skt;
      kt += item.kt;
      lg += item.lg;
    })
    total = skt + kt + lg;
    setSkt(skt);
    setKt(kt);
    setLg(lg);
    setTotal(total);
  }

  const disabledDate = (current: any) => {
    return current > moment().endOf('day');
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };

  const data = {
    labels: dataList.map((item: any) => item.date),
    datasets: [
      {
        label: 'SKT',
        data: dataList.map((item: any) => item.skt),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'KT',
        data: dataList.map((item: any) => item.kt),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'LGU+',
        data: dataList.map((item: any) => item.lg),
        backgroundColor: 'rgba(153, 182, 35, 0.5)',
      },
    ],
  }

  return (
    <div>
      <Typography.Title level={3}>일별 다운로드</Typography.Title>

      <Form form={form} layout="inline" name="form_event" onFinish={onFinish}>
        <Form.Item
          name="periodRange"
          label={`시작일 ~ 종료일`}
          rules={[
            {
              required: true,
              message: '시작일과 종료일을 선택하세요.',
            },
          ]}
        >
          <DatePicker.RangePicker disabledDate={disabledDate} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>

      <Row gutter={12} style={{marginTop: '0.5rem'}}>
        <Col span={6}>
          <Card title="전체">
            <Typography.Title level={4}>{total}</Typography.Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="SKT">
            <Typography.Title level={4}>{skt}</Typography.Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="KT">
            <Typography.Title level={4}>{kt}</Typography.Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="LGU+">
            <Typography.Title level={4}>{lg}</Typography.Title>
          </Card>
        </Col>
      </Row>

      <Bar options={options} data={data} />
    </div>
  );
};

export default AdminStats;