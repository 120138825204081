export class DeviceVO {
  id?: string;
  imei1?: string;
  imei2?: string;
  mno?: string;
  mac?: string;
  modelName?: string;
  printLabel?: string;
  sn?: string;
  catonNo?: string;
  type?: string;
  created?: string;
}
