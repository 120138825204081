const SET_SHOW = 'SET_SHOW';
const SET_PROGRESS = 'SET_PROGRESS';

const initialState = {
  show: false,
  progress: 0,
}

export const setShowProgressModal = (show: boolean) => ({
  type: SET_SHOW,
  show
})

export const setProgressValue = (progress: number) => ({
  type: SET_PROGRESS,
  progress
})

export const ProgressReducer = (state = initialState, action: any) => {
  switch(action.type) {
    case SET_SHOW:
      return {
        ...state,
        show: action.show
      }
    case SET_PROGRESS:
      return {
        ...state,
        progress: action.progress
      }
    default:
      return state;
  }
}
