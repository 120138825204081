export const ROUTES_PATH = {
  Login: "/login",
  SignUp: "/signUp",
  Admin: "/admin/*",
  AdminLog: "log",
  AdminModel: "model",
  AdminDevice: "device",
  AdminAppInfo: "appinfo",
  AdminStats: "stats",
  User: "/*"
}
