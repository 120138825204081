import React, {useEffect, useState} from 'react';
import {
  addDoc,
  collection,
  deleteDoc, doc,
  endBefore,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  QueryConstraint,
  startAfter,
  where
} from "firebase/firestore";
import {firestore} from "../../../firebase";
import {Button, Input, message, Modal, Row, Select, Space, Table, Tooltip, Upload} from "antd";
import moment from "moment";
import {ExclamationCircleOutlined, SearchOutlined, UploadOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {setLoading} from "../../../redux/reducers/NotiReducer";
import {DeviceVO} from "../../model/DeviceVO";
import DeviceEditModal from "./DeviceEditModal";
import {setProgressValue, setShowProgressModal} from "../../../redux/reducers/ProgressReducer";

const PAGE_SIZE = 15;

const AdminDevice = () => {
  const [downloads, setDownloads] = useState<DeviceVO[]>([]);
  const [lastVisible, setLastVisible] = useState<any>();
  const [firstVisible, setFirstVisible] = useState<any>();
  const [showNext, setShowNext] = useState(false);
  const [showPrev, setShowPrev] = useState(false);
  const [showDeviceEditModal, setShowDeviceEditModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<DeviceVO | null>(null);

  const [mno, setMno] = useState<any>(null);
  const [models, setModels] = useState([]);
  const [model, setModel] = useState<any>(null);
  const [imei, setImei] = useState<string>('');

  const dispatch = useDispatch();

  const columns = [
    {
      title: 'imei1',
      dataIndex: 'imei1',
      editable: false,
      key: 'imei1',
      render: (text: any) => (
        <span>{text}</span>
      )
    },
    {
      title: 'imei2',
      dataIndex: 'imei2',
      editable: false,
      key: 'imei2',
      render: (text: any) => (
        <span>{text}</span>
      )
    },
    {
      title: 'mno',
      dataIndex: 'mno',
      editable: false,
      key: 'mno',
      render: (text: any) => (
        <span>{text}</span>
      )
    },
    {
      title: 'mac',
      dataIndex: 'mac',
      editable: false,
      key: 'mac',
      render: (text: any) => (
        <span>{text}</span>
      )
    },
    {
      title: 'modelName',
      dataIndex: 'modelName',
      editable: false,
      key: 'modelName',
      render: (text: any) => (
        <span>{text}</span>
      )
    },
    {
      title: 'printLabel',
      dataIndex: 'printLabel',
      editable: false,
      key: 'printLabel',
      render: (text: any) => (
        <span>{text}</span>
      )
    },
    {
      title: 'sn',
      dataIndex: 'sn',
      editable: false,
      key: 'sn',
      render: (text: any) => (
        <span>{text}</span>
      )
    },
    {
      title: '유형',
      dataIndex: 'type',
      editable: false,
      key: 'type',
      render: (text: any) => (
        <span>{text}</span>
      )
    },
    {
      title: '등록일',
      dataIndex: 'created',
      key: 'created',
      render: (text: any) => (
        <span>{moment(text.seconds * 1000).format('YYYY-MM-DD HH:mm:SS')}</span>
      )
    },
    {
      title: '수정',
      dataIndex: 'id',
      key: 'edit',
      render: (text: any, record: any) => (
        <Space>
          <Button type="primary" ghost onClick={() => {
            setSelectedDevice(record);
            setShowDeviceEditModal(true);
          }} size="small">수정</Button>
          <Button danger type="primary" ghost size="small" onClick={() => confirmDelete(record)}>삭제</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    getModels();
    getDevices();
  }, []);

  const getModels = async () => {
    const querySnapshot = await getDocs(collection(firestore, `models`));
    const tempModels: any = [];
    querySnapshot.forEach((doc) => {
      tempModels.push({id: doc.id, name: doc.data().name});
    });
    setModels(tempModels);
  }

  const getDevices = async () => {
    let args: QueryConstraint[] = [orderBy('created', 'desc'), limit(PAGE_SIZE)];
    if (imei) {
      args.unshift(where('imei1', '==', imei));
    }
    if (model) {
      args.unshift(where('modelName', '==', model));
    }
    if (mno) {
      args.unshift(where('mno', '==', mno));
    }
    const querySnapshot = await getDocs(query(collection(firestore, 'devices'), ...args));

    if (querySnapshot.docs.length < PAGE_SIZE) {
      setShowNext(false);
    } else {
      setShowNext(true);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length-1]);
    }

    const tempDownloads: DeviceVO[] = [];
    querySnapshot.forEach((doc) => {
      tempDownloads.push({id: doc.id, ...doc.data()});
    })

    setDownloads(tempDownloads);
  }

  const getNextPage = async () => {
    let args: QueryConstraint[] = [orderBy('created', 'desc'), startAfter(lastVisible), limit(PAGE_SIZE)];
    if (imei) {
      args.unshift(where('imei1', '==', imei));
    }
    if (model) {
      args.unshift(where('modelName', '==', model));
    }
    if (mno) {
      args.unshift(where('mno', '==', mno));
    }
    const querySnapshot = await getDocs(query(collection(firestore, 'devices'), ...args));

    if (querySnapshot.docs.length === 0) {
      setShowNext(false);
      return;
    }

    if (querySnapshot.docs.length < PAGE_SIZE) {
      setShowPrev(true);
      setFirstVisible(querySnapshot.docs[0]);
      setShowNext(false);
    } else {
      setShowPrev(true);
      setFirstVisible(querySnapshot.docs[0]);
      setShowNext(true);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length-1]);
    }

    const tempDownloads: DeviceVO[] = [];
    querySnapshot.forEach((doc) => {
      tempDownloads.push({id: doc.id, ...doc.data()});
    })
    setDownloads(tempDownloads);
  }

  const getPrevPage = async () => {
    let args: QueryConstraint[] = [orderBy('created', 'desc'), endBefore(firstVisible), limitToLast(PAGE_SIZE)];
    if (imei) {
      args.unshift(where('imei1', '==', imei));
    }
    if (model) {
      args.unshift(where('modelName', '==', model));
    }
    if (mno) {
      args.unshift(where('mno', '==', mno));
    }
    const querySnapshot = await getDocs(query(collection(firestore, 'devices'), ...args));

    if (querySnapshot.docs.length < PAGE_SIZE) {
      setShowPrev(false);
      setShowNext(true);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length-1]);
    } else {
      setShowPrev(true);
      setFirstVisible(querySnapshot.docs[0]);
      setShowNext(true);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length-1]);
    }

    const tempDownloads: DeviceVO[] = [];
    querySnapshot.forEach((doc) => {
      tempDownloads.push({id: doc.id, ...doc.data()});
    })
    setDownloads(tempDownloads);
  }

  const refreshPage = () => {
    getDevices();
  }

  const onBeforeUpload = (info: any) => {
    console.log(info);
    const reader = new FileReader();
    reader.onload = async (progressEvent: any) => {
      const result = progressEvent?.target.result;
      const lineArray = result.split(/\r\n|\n|\r/);

      if (!lineArray[0]) {
        message.info('올바른 화일아 아닙니다..')
        return;
      }

      const heads = lineArray[0].split(',');
      if (heads[0] !== 'imei1' || heads[1] !== 'imei2' || heads[2] !== 'mno'
        || heads[3] !== 'mac' || heads[4] !== 'sn' || heads[5] !== 'catonNo'
        || heads[6] !== 'printLabel' || heads[7] !== 'modelName' || heads[8] !== 'type') {
        message.info('화일양식이 맞지 않습니다.')
        return;
      }

      dispatch(setLoading(true));
      dispatch(setShowProgressModal(true));

      let index = 0;
      const deviceUploadList: any = [];

      for(let line of lineArray) {
        ++index;
        if (index === 1) {
          // 헤더라인 스킵
          continue;
        }
        try {
          const fields = line.split(',');

          let device = new DeviceVO();
          device.imei1 = fields[0].trim();
          device.imei2 = fields[1].trim();
          device.mno = fields[2].trim();
          device.mac = fields[3].trim();
          device.sn = fields[4].trim();
          device.catonNo = fields[5].trim();
          device.printLabel = fields[6].trim();
          device.modelName = fields[7].trim();
          device.type = fields[8].trim();

          // imei1, mno, type 체크
          if (!device.imei1 || !device.mno || !device.type) {
            continue;
          }

          deviceUploadList.push(device);
        } catch (e) {
          console.log('catch error: ' + e);
          continue;
        }
      }

      let progressCount = 0;
      let successCount = 0; // 성공횟수
      const totalCount = deviceUploadList.length; // 전체 갯수
      const duplicatedList = [];

      for (let d of deviceUploadList) {
        try {
          // imei1 중복 체크
          const q = query(collection(firestore, "devices"), where("imei1", "==", d.imei1));
          const querySnapshot = await getDocs(q);
          if (querySnapshot.size > 0) {
            duplicatedList.push(d.imei1);
            throw 'duplicated error';
          }

          await addDoc(collection(firestore, 'devices'), {
            imei1: d.imei1,
            imei2: d.imei2,
            mno: d.mno,
            mac: d.mac,
            sn: d.sn,
            catonNo: d.catonNo,
            printLabel: d.printLabel,
            modelName: d.modelName,
            type: d.type,
            created: new Date()
          });

          ++successCount;
        } catch(e) {
          console.log('error: ', e);
        }

        ++progressCount;
        // await timeout(0);
        dispatch(setProgressValue(progressCount * 100 / totalCount));
      }

      dispatch(setShowProgressModal(false));
      dispatch(setLoading(false));

      //
      Modal.confirm({
        title: '업로드 결과',
        content: <>
          <p>imei1 중복: {duplicatedList.join(',')}</p>
          <p>성공: {`${successCount} / ${totalCount}`}</p>
        </>,
        okText: '확인',
        onOk: () => getDevices()
      });
    };
    reader.readAsText(info);

    return false;
  }

  const deleteDevice = async (id: string) => {
    try {
      await deleteDoc(doc(firestore, `devices`, id));
      getDevices();
      message.success('삭제되었습니다.');
    } catch (e) {
      console.log(e);
      message.error('실패하였습니다.');
    }
  }

  const confirmDelete = async (record: any) => {
    Modal.confirm({
      title: 'Do you want to delete this Device?',
      icon: <ExclamationCircleOutlined />,
      content: `${record.imei1}`,
      onOk() {
        deleteDevice(record.id);
      },
    });
  }

  return (
    <div>
      <Row justify="end">
        <Upload accept=".csv" beforeUpload={onBeforeUpload}>
          <Space>
            <Tooltip placement="top" title={`device_sample.csv download`}>
              <a href='/download/device_sample.csv'>sample download</a>
            </Tooltip>
            <Button type="primary" icon={<UploadOutlined />}>Click to Upload</Button>
          </Space>
        </Upload>
      </Row>

      <Row justify="start" style={{margin: '1rem 0'}}>
        <Space>
          <Select style={{ width: 120 }} placeholder="통신사 전체" value={mno} onChange={(v) => setMno(v)}>
            <Select.Option value="">통신사 전체</Select.Option>
            <Select.Option value="SKT">SKT</Select.Option>
            <Select.Option value="KT">KT</Select.Option>
            <Select.Option value="LG U+">LG U+</Select.Option>
          </Select>
          <Select style={{ width: 120 }} placeholder="모델 전체" value={model} onChange={(v) => setModel(v)}>
            <Select.Option value="">모델 전체</Select.Option>
            {models.map((model: any) => <Select.Option value={model.name} key={model.name}>{model.name}</Select.Option>)}
          </Select>
          <Input placeholder="imei" value={imei} onChange={(e: any) => setImei(e.target.value)}></Input>
          <Button type="primary" icon={<SearchOutlined />} onClick={getDevices}>검색</Button>
        </Space>
      </Row>

      <Table columns={columns} dataSource={downloads} pagination={false}
             size="small"
             rowKey={record => record.id || ''}></Table>


          <Row justify="center" style={{marginTop: '1rem'}}>
            <Space>
              { firstVisible &&  <Button type="primary" onClick={getPrevPage} disabled={!showPrev}>prev page</Button> }
              { lastVisible &&  <Button type="primary" onClick={getNextPage} disabled={!showNext}>next page</Button> }
            </Space>
          </Row>

      <DeviceEditModal modal={showDeviceEditModal} closeModal={() => setShowDeviceEditModal(false)}
                       device={selectedDevice} refreshPage={refreshPage}></DeviceEditModal>
    </div>
  );
};

export default AdminDevice;
