import React, {useEffect, useState} from 'react';
import {collection, getDocs, limit, orderBy, query} from "firebase/firestore";
import {firestore} from "../../../firebase";
import {DownloadVO} from "../../model/DownloadVO";
import {Table} from "antd";
import moment from "moment";

const AdminLog = () => {
  const [downloads, setDownloads] = useState<DownloadVO[]>([]);

  useEffect(() => {
    getDownloads();
  }, []);

  const columns = [
    {
      title: 'sn',
      dataIndex: 'sn',
      editable: false,
      key: 'sn',
      render: (text: any) => (
        <span>{text}</span>
      )
    },
    {
      title: 'model',
      dataIndex: 'model',
      editable: false,
      key: 'model',
      render: (text: any) => (
        <span>{text}</span>
      )
    },
    {
      title: 'clientVersion',
      dataIndex: 'clientVersion',
      editable: false,
      key: 'clientVersion',
      render: (text: any) => (
        <span>{text}</span>
      )
    },
    {
      title: 'mno',
      dataIndex: 'mno',
      editable: false,
      key: 'mno',
      render: (text: any) => (
        <span>{text}</span>
      )
    },
    {
      title: 'type',
      dataIndex: 'type',
      editable: false,
      key: 'type',
      render: (text: any) => (
        <span>{text}</span>
      )
    },
    {
      title: '등록일',
      dataIndex: 'created',
      key: 'created',
      render: (text: any) => (
        <span>{moment(text.seconds * 1000).format('YYYY-MM-DD HH:mm:SS')}</span>
      )
    }
  ];

  const getDownloads = async () => {
    const q = query(collection(firestore, 'downloads'), orderBy('created', 'desc'), limit(15));
    const querySnapshot = await getDocs(q);
    const tempDownloads: DownloadVO[] = [];
    querySnapshot.forEach((doc) => {
      tempDownloads.push({id: doc.id, ...doc.data()});
    })
    console.log(tempDownloads);
    setDownloads(tempDownloads);
  }

  return (
    <div>
      <Table columns={columns} dataSource={downloads} pagination={false}
             rowKey={record => record.id || ''}></Table>
    </div>
  );
};

export default AdminLog;
