import React from 'react';
import {Layout, Spin} from "antd";
import {ROUTES_PATH} from "./routes";
import {useSelector} from "react-redux";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import SignUp from "./pages/signUp/SignUp";
import Login from "./pages/login/Login";
import {AuthProvider} from "./Auth";
import AdminIndex from "./pages/admin";
import AdminLog from "./pages/admin/download";
import AdminDevice from "./pages/admin/device/AdminDevice";

import './App.scss';
import AdminStats from "./pages/admin/stats/AdminStats";
import AppInfo from "./pages/admin/device/AppInfo";
import AdminModel from "./pages/admin/device/AdminModel";
import ProgressModal from "./components/ProgressModal";

function App() {
  const loading = useSelector((state: any) => state.Noti.loading);
  const show = useSelector((state: any) => state.Progress.show);
  const progress = useSelector((state: any) => state.Progress.progress);

  return (
    <AuthProvider>
      <Spin spinning={loading} size="large">
        <Layout style={{height: '100vh'}}>
          <Layout.Content>
            <BrowserRouter>
              <Routes>
                <Route path={ROUTES_PATH.Login} element={<Login />}></Route>
                <Route path={ROUTES_PATH.SignUp} element={<SignUp />}></Route>
                {/*관리자 사이트*/}
                <Route path={ROUTES_PATH.Admin} element={<AdminIndex />}>
                  <Route path={ROUTES_PATH.AdminLog} element={<AdminLog />}></Route>
                  <Route path={ROUTES_PATH.AdminModel} element={<AdminModel />}></Route>
                  <Route path={ROUTES_PATH.AdminAppInfo} element={<AppInfo />}></Route>
                  <Route path={ROUTES_PATH.AdminDevice} element={<AdminDevice />}></Route>
                  <Route path={ROUTES_PATH.AdminStats} element={<AdminStats />}></Route>
                </Route>

                <Route
                  path="*"
                  element={<Navigate to="/admin" />}
                />
              </Routes>
            </BrowserRouter>
          </Layout.Content>
        </Layout>
      </Spin>
      <ProgressModal show={show} progress={progress}></ProgressModal>
    </AuthProvider>
  );
}

export default App;
