import React, {useEffect, useState} from 'react';
import {Button, message, Modal, Row, Select, Space, Table} from "antd";
import {ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";
import ModelAddModal from "./ModelAddModal";
import {addDoc, collection, deleteDoc, doc, getDocs} from "firebase/firestore";
import {firestore} from "../../../firebase";
import {AppInfoVO} from "../../model/AppInfoVO";

const AdminModel = () => {
  const [showModelAddModal, setShowModelAddModal] = useState(false);
  const [models, setModels] = useState([]);

  useEffect(() => {
    getModels();
  }, []);

  const columns = [
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
      render: (name: any) => (
        <span>{name}</span>
      )
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (id: any, record: any) =>  <Row justify="end">
        <Button danger type="primary" ghost size="small" onClick={() => confirmDelete(record)}>삭제</Button>
      </Row>,
    }
  ]

  const getModels = async () => {
    const querySnapshot = await getDocs(collection(firestore, `models`));
    const tempModels: any = [];
    querySnapshot.forEach((doc) => {
      tempModels.push({id: doc.id, name: doc.data().name});
    });
    setModels(tempModels);
  }

  const addModel = async (values: any) => {
    try {
      await addDoc(collection(firestore, `models`), {
        name: values.name
      });

      message.success('등록하였습니다.');
      getModels();
    } catch(e) {
      message.error('다시 시도하세요.');
    }
  }

  const deleteModel = async (id: string) => {
    try {
      await deleteDoc(doc(firestore, `models`, id));
      getModels();
    } catch (e) {
      console.log(e);
    }
  }

  const confirmDelete = async (record: any) => {
    Modal.confirm({
      title: 'Do you want to delete this Model?',
      icon: <ExclamationCircleOutlined />,
      content: `${record.name}`,
      onOk() {
        deleteModel(record.id);
      },
    });
  }

  return (
    <div>
      <Row justify="end" style={{marginBottom: '1rem'}}>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setShowModelAddModal(true)}>추가</Button>
      </Row>

      <Table columns={columns} dataSource={models} pagination={false}
             size="small"
             rowKey="id"></Table>

      <ModelAddModal modal={showModelAddModal} closeModal={() => setShowModelAddModal(false)} addModel={addModel}></ModelAddModal>
    </div>
  );
};

export default AdminModel;