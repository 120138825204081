// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import { getAuth, Auth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD7W1n0TV9CDNGMGMFVJK3artIqLtouk0M",
  authDomain: "aroot-898a1.firebaseapp.com",
  projectId: "aroot-898a1",
  storageBucket: "aroot-898a1.appspot.com",
  messagingSenderId: "746811239572",
  appId: "1:746811239572:web:50c91d9076414df4359881"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
// const auth = app.auth();
// const firestore = firebase.firestore();
// const messaging = app.messaging();
