import React from 'react';
import {Button, Form, Input, InputNumber, Modal, Radio, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";

const AppInfoAddModal = ({modal, closeModal, addModel}: any) => {
  const [form] = Form.useForm();

  const onSubmit = async (values: any) => {
    console.log(values);

    addModel(values);

    form.resetFields();
    closeModal();
  }

  return (
    <Modal
      title="App 정보 등록"
      visible={modal}
      style={{ top: 20 }}
      okText="저장"
      onOk={() => {
        form.validateFields().then((values) => {
          onSubmit(values)
        })
      }}
      cancelText="취소"
      onCancel={() => {
        form.resetFields();
        closeModal()
      }}
    >
      <Form form={form} layout="vertical" name="model_form" size="small">
        <Form.Item
          label="name"
          name="name"
          rules={[
            {
              required: true,
              message: 'name 입력하세요.',
            },
          ]}
        >
          <Input placeholder="name"></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AppInfoAddModal;
