import React, {useEffect} from 'react';
import {Button, Form, Input, InputNumber, Modal, Radio, Select, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";

const AppInfoEditModal = ({modal, closeModal, selectedInfo, models, editAppInfo}: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!selectedInfo) {
      return;
    }

    form.setFieldsValue({
      model: selectedInfo.model,
      order: selectedInfo.order,
      mandatory: selectedInfo.mandatory === 'true',
      appName: selectedInfo.appName,
      packageName: selectedInfo.packageName,
      iconFilename: selectedInfo.iconUrl.substring(selectedInfo.iconUrl.indexOf('/icon/') + 6)
    });
  }, [modal]);

  const onSubmit = async (values: any) => {
    editAppInfo(values);

    form.resetFields();
    closeModal();
  }

  return (
    <Modal
      title="App 정보 등록"
      visible={modal}
      style={{ top: 20 }}
      okText="저장"
      onOk={() => {
        form.validateFields().then((values) => {
          onSubmit(values)
        })
      }}
      cancelText="취소"
      onCancel={() => {
        form.resetFields();
        closeModal()
      }}
    >
      <Form form={form} layout="vertical" name="app_info_form" size="small">
        <Form.Item
          label="model"
          name="model"
          rules={[
            {
              required: true,
              message: 'model 선택하세요.',
            },
          ]}
        >
          <Select>
            {
              models.map((model: any) => <Select.Option value={model.name} key={model.name}>{model.name}</Select.Option>)
            }
          </Select>
        </Form.Item>
        <Form.Item
          label="order"
          name="order"
          rules={[
            {
              required: true,
              message: 'order 입력하세요.',
            },
          ]}
        >
          <InputNumber placeholder="order" min={0}></InputNumber>
        </Form.Item>
        <Form.Item
          label="mandatory"
          name="mandatory"
          rules={[
            {
              required: true,
              message: '필수/옵션을 선택하세요.',
            },
          ]}
        >
          <Radio.Group
            options={[{label: '옵션', value: false}, {label: '필수', value: true}]}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <Form.Item
          label="appName"
          name="appName"
          rules={[
            {
              required: true,
              message: 'appName 입력하세요.',
            },
          ]}
        >
          <Input placeholder="appName"></Input>
        </Form.Item>
        <Form.Item
          label="packageName"
          name="packageName"
          rules={[
            {
              required: true,
              message: 'packageName 입력하세요.',
            },
          ]}
        >
          <Input placeholder="packageName"></Input>
        </Form.Item>

        <Form.Item
          label="iconFilename"
          name="iconFilename"
          rules={[
            {
              required: true,
              message: 'icon Filename 입력하세요.',
            },
          ]}
        >
          <Input placeholder="packageName"></Input>
        </Form.Item>

        <Form.Item
          name="apk"
          label="apk"
        >
          <Upload listType="text" beforeUpload={() => false}>
            <Button icon={<UploadOutlined/>}>apk 업로드</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="icon"
          label="icon"
        >
          <Upload listType="picture" beforeUpload={() => false}>
            <Button icon={<UploadOutlined/>}>icon 이미지 업로드</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AppInfoEditModal;
