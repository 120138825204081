import React from 'react';
import {Button, Form, Input, InputNumber, Modal, Radio, Select, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";

const AppInfoAddModal = ({modal, closeModal, models, addAppInfo}: any) => {
  const [form] = Form.useForm();

  const onSubmit = async (values: any) => {
    console.log(values);

    addAppInfo(values);

    form.resetFields();
    closeModal();
  }

  return (
    <Modal
      title="App 정보 등록"
      visible={modal}
      style={{ top: 20 }}
      okText="저장"
      onOk={() => {
        form.validateFields().then((values) => {
          onSubmit(values)
        })
      }}
      cancelText="취소"
      onCancel={() => {
        form.resetFields();
        closeModal()
      }}
    >
      <Form form={form} layout="vertical" name="app_info_form" size="small">
        <Form.Item
          label="model"
          name="model"
          rules={[
            {
              required: true,
              message: 'model 선택하세요.',
            },
          ]}
        >
          <Select>
            {
              models.map((model: any) => <Select.Option value={model.name} key={model.name}>{model.name}</Select.Option>)
            }
          </Select>
        </Form.Item>
        <Form.Item
          label="order"
          name="order"
          rules={[
            {
              required: true,
              message: 'order 입력하세요.',
            },
          ]}
        >
          <InputNumber placeholder="order" min={0}></InputNumber>
        </Form.Item>
        <Form.Item
          label="mandatory"
          name="mandatory"
          rules={[
            {
              required: true,
              message: '필수/옵션을 선택하세요.',
            },
          ]}
        >
          <Radio.Group
            options={[{label: '옵션', value: false}, {label: '필수', value: true}]}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <Form.Item
          label="appName"
          name="appName"
          rules={[
            {
              required: true,
              message: 'appName 입력하세요.',
            },
          ]}
        >
          <Input placeholder="appName"></Input>
        </Form.Item>
        <Form.Item
          label="packageName"
          name="packageName"
          rules={[
            {
              required: true,
              message: 'packageName 입력하세요.',
            },
          ]}
        >
          <Input placeholder="packageName"></Input>
        </Form.Item>

        <Form.Item
          name="apk"
          label="apk"
          rules={[
            {
              required: true,
              message: 'apk를 업로드하세요',
            },
          ]}
        >
          <Upload listType="text" beforeUpload={() => false}>
            <Button icon={<UploadOutlined/>}>apk 업로드</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="icon"
          label="icon"
          rules={[
            {
              required: true,
              message: 'icon을 업로드하세요',
            },
          ]}
        >
          <Upload listType="picture" beforeUpload={() => false}>
            <Button icon={<UploadOutlined/>}>icon 이미지 업로드</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AppInfoAddModal;
