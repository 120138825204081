import React from 'react';
import {Modal, Progress, Row} from "antd";

const ProgressModal = ({show, progress}: any) => {
  return (
    <Modal
      title="Wait ..."
      visible={show}
      closable={false}
      footer={null}
    >
      <Row justify="center">
        <Progress
          type="circle"
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
          percent={Math.round(progress)}
        />
      </Row>
    </Modal>
  );
};

export default ProgressModal;
