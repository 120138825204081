export class AppInfoVO {
  id?: string;
  apkUrl?: string;
  appName?: string;
  iconUrl?: string;
  mandatory?: any;
  order?: number;
  packageName?: string;
  model?: string;
}
