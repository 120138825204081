import React, {useEffect} from 'react';
import {Form, Input, message, Modal, Select} from "antd";
import {doc, setDoc} from "firebase/firestore";
import { firestore } from '../../../firebase';
import {DeviceVO} from "../../model/DeviceVO";

const DeviceEditModal = ({modal, closeModal, device, refreshPage}: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!device) {
      return;
    }

    const fields = {
      imei1: device.imei1,
      imei2: device.imei2,
      mno: device.mno,
      mac: device.mac,
      modelName: device.modelName,
      printLabel: device.printLabel,
      sn: device.sn,
      catonNo: device.catonNo,
      type: device.type
    }

    form.setFieldsValue(fields);
  })

  const onSubmit = (values: DeviceVO) => {
    console.log(values);
    handleSave(values);
  }

  const handleSave = async (values: DeviceVO) => {
    try {
      // 수정하기, 기존데이터와 합치기 merge 옵션 사용
      await setDoc(doc(firestore, "devices", device.id), {
        ...values
      }, {merge: true});
      message.success('수정하였습니다.');
      refreshPage();
      closeModal();
    } catch(e) {
      message.error('에러가 발생하였습니다. 다시 시도하세요');
    }
  }

  return (
    <Modal
      title="수정"
      visible={modal}
      style={{ top: 20 }}
      okText="확인"
      onOk={() => {
        form.validateFields().then((values) => {
          onSubmit(values)
        })
      }}
      cancelText="취소"
      onCancel={() => {
        closeModal()
      }}
    >
      <Form form={form} layout="vertical" name="edit_form_device" size="small">
        <Form.Item
          label="imei1"
          name="imei1"
          rules={[
            {
              required: true,
              message: 'imei1을 입력하세요.',
            },
          ]}
        >
          <Input placeholder="imei1을 입력하세요."></Input>
        </Form.Item>
        <Form.Item
          label="imei2"
          name="imei2"
        >
          <Input placeholder="imei2을 입력하세요."></Input>
        </Form.Item>
        <Form.Item
          label="mno"
          name="mno"
          rules={[
            {
              required: true,
              message: 'mno 입력하세요.',
            },
          ]}
        >
          <Select placeholder="통신사 선택">
            <Select.Option value="SKT">SKT</Select.Option>
            <Select.Option value="KT">KT</Select.Option>
            <Select.Option value="LG U+">LG U+</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="mac"
          name="mac"
        >
          <Input placeholder="mac 입력하세요."></Input>
        </Form.Item>
        <Form.Item
          label="modelName"
          name="modelName"
        >
          <Input placeholder="modelName 입력하세요."></Input>
        </Form.Item>
        <Form.Item
          label="printLabel"
          name="printLabel"
        >
          <Input placeholder="printLabel 입력하세요."></Input>
        </Form.Item>
        <Form.Item
          label="sn"
          name="sn"
        >
          <Input placeholder="sn 입력하세요."></Input>
        </Form.Item>
        <Form.Item
          label="catonNo"
          name="catonNo"
        >
          <Input placeholder="catonNo 입력하세요."></Input>
        </Form.Item>
        <Form.Item
          label="유형"
          name="type"
          rules={[
            {
              required: true,
              message: '유형을 선택하세요.',
            },
          ]}
        >
          <Select placeholder="유형 선택">
            <Select.Option value="test">test</Select.Option>
            <Select.Option value="sales">sales</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DeviceEditModal;