import React, {useEffect, useState} from 'react';
import {addDoc, collection, deleteDoc, doc, getDocs, query, setDoc, where} from "firebase/firestore";
import {firestore, storage} from "../../../firebase";
import {Button, message, Modal, Row, Select, Space, Table} from "antd";
import {ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {AppInfoVO} from "../../model/AppInfoVO";
import AppInfoAddModal from "./AppInfoAddModal";
import {setLoading} from "../../../redux/reducers/NotiReducer";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import AppInfoEditModal from "./AppInfoEditModal";

const AppInfo = () => {
  const [mno, setMno] = useState('skt');
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState();
  const [infos, setInfos] = useState<AppInfoVO[]>([]);
  const [showAppInfoAddModal, setShowAppInfoAddModal] = useState(false);
  const [showAppInfoEditModal, setShowAppInfoEditModal] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState<any | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    getModels();
  }, []);

  useEffect(() => {
    getAppInfo();
  }, [mno, selectedModel]);

  const columns = [
    {
      title: 'mno',
      dataIndex: 'mno',
      key: 'mno',
      render: (text: any) => (
        <span>{mno}</span>
      )
    },
    {
      title: 'model',
      dataIndex: 'infos',
      key: 'model',
      render: (text: any, record: any) => <span>{record.model}</span>
    },
    {
      title: 'appInfos',
      dataIndex: 'mno',
      key: 'infos',
      render: (text: any, record: any) => (<ul>
          <li key="apkUrl">apkUrl: {record.apkUrl}</li>
          <li key="appName">appName: {record.appName}</li>
          <li key="iconUrl">iconUrl: {record.iconUrl}</li>
          <li key="mandatory">mandatory: {record.mandatory}</li>
          <li key="packageName">packageName: {record.packageName}</li>
          <li key="order">order: {record.order}</li>
        </ul>
      )
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (id: any, record: any) =>  <Row justify="end"><Space>
        <Button type="primary" ghost size="small" onClick={() => {
          setSelectedInfo(record);
          setShowAppInfoEditModal(true);
        }}>정보수정</Button>
        <Button danger type="primary" ghost size="small" onClick={() => confirmDelete(record)}>삭제</Button>
      </Space></Row>,
    }
  ];

  const getModels = async () => {
    const querySnapshot = await getDocs(collection(firestore, `models`));
    const tempModels: any = [];
    querySnapshot.forEach((doc) => {
      tempModels.push({id: doc.id, name: doc.data().name});
    });
    setModels(tempModels);
  }

  const getAppInfo = async () => {
    const appListRef = collection(firestore, `appInfos/${mno}/appList`);
    let q;
    if (!selectedModel) {
      q = query(appListRef);
    } else {
      q = query(appListRef, where('model', '==', selectedModel));
    }

    const querySnapshot = await getDocs(q);
    const appInfos: AppInfoVO[] = [];
    querySnapshot.forEach((doc) => {
      const appInfo = new AppInfoVO();
      appInfo.id = doc.id;
      appInfo.apkUrl = doc.data().apkUrl;
      appInfo.appName = doc.data().appName;
      appInfo.iconUrl = doc.data().iconUrl;
      appInfo.mandatory = doc.data().mandatory ? 'true' : 'false';
      appInfo.order = doc.data().order;
      appInfo.packageName = doc.data().packageName;
      appInfo.model = doc.data().model;
      appInfos.push(appInfo);
    });
    setInfos(appInfos);
  }

  const deleteAppInfo = async (id: string) => {
    try {
      await deleteDoc(doc(firestore, `appInfos/${mno}/appList`, id));
      getAppInfo();
    } catch (e) {
      console.log(e);
    }
  }

  const confirmDelete = async (record: any) => {
    Modal.confirm({
      title: 'Do you want to delete this AppInfo?',
      icon: <ExclamationCircleOutlined />,
      content: `${record.appName}`,
      onOk() {
        deleteAppInfo(record.id);
      },
    });
  }

  const addAppInfo = async (values: any) => {
    console.log(values);

    try {
      dispatch(setLoading(true));

      const apkUrl = await  uploadApk(values.apk.fileList[0].originFileObj, values.apk.fileList[0].name);
      const iconUrl = await  uploadIcon(values.icon.fileList[0].originFileObj, values.icon.fileList[0].name, values.icon.fileList[0].type);

      await addDoc(collection(firestore, `appInfos/${mno}/appList`), {
        apkUrl: apkUrl,
        appName: values.appName,
        iconUrl: iconUrl,
        mandatory: values.mandatory,
        order: values.order,
        packageName: values.packageName,
        model: values.model
      });

      getAppInfo();
      dispatch(setLoading(false));
      message.success('추가하였습니다.');
    } catch (e) {
      console.log(e);
      dispatch(setLoading(false));
      message.error('다시 시도하세요.');
    }
  }

  const editAppInfo = async (values: any) => {
    try {
      dispatch(setLoading(true));

      const body: any = {
        appName: values.appName,
        mandatory: values.mandatory,
        order: values.order,
        packageName: values.packageName,
        model: values.model
      }

      if (values.apk) {
        body.apkUrl = await  uploadApk(values.apk.fileList[0].originFileObj, values.apk.fileList[0].name);
      }

      if (values.icon) {
        body.iconUrl = await uploadIcon(values.icon.fileList[0].originFileObj, values.icon.fileList[0].name, values.icon.fileList[0].type);
      }

      await setDoc(doc(firestore, `appInfos/${mno}/appList`, selectedInfo.id), body, {merge: true});

      getAppInfo();
      dispatch(setLoading(false));
      message.success('수정하였습니다.');
    } catch (e) {
      console.log(e);
      dispatch(setLoading(false));
      message.error('다시 시도하세요.');
    }
  }

  const uploadApk = async (file: any, filename: string) => {
    try {
      const apkRef = ref(storage, `${convertMno(mno)}/${filename}`);

      // 'file' comes from the Blob or File API
      const result = await uploadBytes(apkRef, file);

      return `gs://${result.ref.bucket}/${result.ref.fullPath}`;
    } catch (e) {
      throw e;
      console.log(e);
    }
  }

  const uploadIcon = async (file: any, filename: string, type: string) => {
    try {
      const imageRef = ref(storage, `icon/${convertMno(mno)}/${filename}`);

      // 'file' comes from the Blob or File API
      const result = await uploadBytes(imageRef, file, {
        contentType: type,
      });

      // return public url
      const url = await getDownloadURL(imageRef);
      return url;
    } catch (e) {
      throw e;
      console.log(e);
    }
  }

  const convertMno = (mno: string) => {
    return mno.replace(/\s+/g, '');
  }

  return (
    <div>
      <Row justify="space-between" style={{marginBottom: '1rem'}}>
        <Space>
          <Select style={{ width: 120 }} placeholder="통신사 선택" value={mno} onChange={(v) => setMno(v)}>
            <Select.Option value="skt">SKT</Select.Option>
            <Select.Option value="kt">KT</Select.Option>
            <Select.Option value="lg u+">LG U+</Select.Option>
          </Select>
          <Select style={{ width: 120 }} placeholder="전체" value={selectedModel} onChange={(v) => setSelectedModel(v)}>
            {models.map((model: any) => <Select.Option value={model.name} key={model.name}>{model.name}</Select.Option>)}
          </Select>
        </Space>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setShowAppInfoAddModal(true)}>추가</Button>
      </Row>
      <Table columns={columns} dataSource={infos} pagination={false}
             size="small"
             rowKey="id"></Table>

      <AppInfoAddModal modal={showAppInfoAddModal} closeModal={() => setShowAppInfoAddModal(false)}
                       models={models}
                       addAppInfo={addAppInfo}></AppInfoAddModal>
      <AppInfoEditModal modal={showAppInfoEditModal} closeModal={() => setShowAppInfoEditModal(false)}
                        models={models}
                        selectedInfo={selectedInfo}
                        editAppInfo={editAppInfo}></AppInfoEditModal>
    </div>
  );
};

export default AppInfo;
