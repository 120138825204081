import {Col, Dropdown, Layout, Menu, Row, Typography} from "antd";
import {Link, Navigate, Outlet, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {ROUTES_PATH} from "../../routes";
import React, {useContext} from "react";
import {useDispatch} from "react-redux";
import {
  AppstoreOutlined,
  HomeTwoTone,
  LeftOutlined,
  MailOutlined,
  MenuOutlined,
  SettingOutlined
} from "@ant-design/icons";
import {setUser} from "../../redux/reducers/UserReducer";
import {signOut} from "firebase/auth";
import {auth} from "../../firebase";

import styles from "./AdminIndex.module.scss";
import AdminLog from "./download";
import {jwtUtils} from "../../utils/jwtUtils";
import {AuthContext} from "../../Auth";

const {Content, Header, Footer} = Layout;
const {Text} = Typography;

const AdminIndex = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {currentUser} = useContext(AuthContext);

  if (!jwtUtils.isAuth(currentUser)) {
    return <Navigate to="/login" />;
  }

  const logout = () => {
    // todo: 인증 토큰은 currentUser의 accessToken으로 갖고 오기 때문에 파이어베이스 로그아웃
    signOut(auth);
    navigate('/login');
  }

  const handleClick = (e: any) => {
    console.log('click ', e);
  };

  const menu = (
    <Menu>
      <Menu.Item key={3} onClick={logout}>
        <Text strong>
          logout
        </Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className={styles.container}>
      <Header className={styles.header}>
        {/*left*/}
        {
          location.pathname === '/admin' ? <HomeTwoTone onClick={() => navigate(ROUTES_PATH.Admin)} />
            : <LeftOutlined style={{fontSize: '1.4rem'}} onClick={() => navigate(-1)} />
        }
        {/*center*/}
        { location.pathname === '/admin' && <span>관리자 홈</span> }
        {/*right*/}
        <Dropdown overlay={menu} placement="bottomRight">
          <MenuOutlined />
        </Dropdown>
      </Header>

      <Content className={styles.body}>
        <Row style={{height: '100%'}}>
          <Col xs={4} style={{background: 'white'}}>
            <Menu
              onClick={handleClick}
              style={{ width: '100%' }}
              /*              defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}*/
              selectedKeys={[location.pathname]}
              mode="inline"
            >
              <Menu.SubMenu key="sub1" icon={<MailOutlined />} title="다운로드">
                <Menu.Item key="/admin/log">
                  <span>로그</span>
                  <Link to="/admin/log"></Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key="sub2" icon={<AppstoreOutlined />} title="관리">
                <Menu.Item key="/admin/model">
                  <span>모델관리</span>
                  <Link to="/admin/model"></Link>
                </Menu.Item>
                <Menu.Item key="/admin/device">
                  <span>디바이스관리</span>
                  <Link to="/admin/device"></Link>
                </Menu.Item>
                <Menu.Item key="/admin/appinfo">
                  <span>앱정보관리</span>
                  <Link to="/admin/appinfo"></Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key="sub3" icon={<SettingOutlined />} title="통계">
                <Menu.Item key="/admin/stats">
                  <span>일별다운로드</span>
                  <Link to="/admin/stats"></Link>
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </Col>

          <Col xs={20} style={{padding: '1rem'}}>
            <Outlet></Outlet>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default AdminIndex;
